import { useContext, useState, useCallback } from "react";
import { Toaster } from "react-hot-toast";
import { DAppContext } from "./context";
import LeftAv from "./assets/av2.gif";
import RightAv from "./assets/av1.gif";
import badge1 from "./assets/badge1.png";
import badge2 from "./assets/badge2.png";
import badge3 from "./assets/badge3.png";
import Koda from "./assets/koda.png";
import "./styles/index.css";
import { Loader } from "./components/Loader";

function App() {
  const [count, setCount] = useState(1);
  const {
    userData,
    connectBrowserWallet,
    contractDetails,
    mint,
    loading,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount((prev) => prev + 1);
      return;
    }

    if (pos === "minus" && count - 1 !== 0) {
      setCount((prev) => prev - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, mint]);

  return (
    <section id="home">
      <img src={LeftAv} alt="" className="avatar avatar-left" />
      <div className="mint--container">
        {!loading && (
          <div className="mint">
            <div className="mint__images">
              <img src={badge2} alt="Character" />
              <img src={badge3} alt="Character" />
              <img src={badge1} alt="Character" />
            </div>
            <div>
              <img src={Koda} alt="Koda" className="mint__koda" />
              <div className="mint__wrap">
                <span className="btn--title">BL NFT RANDOM MINT</span>
                <span className="btn--title">
                  {((contractDetails?.price || 0.3) * count).toFixed(1)} ETH
                </span>
              </div>
              {!!contractDetails && (
                <p className="mint__nft-left">
                  {+contractDetails?.totalSupplyNFT -
                    +contractDetails?.totalMinted}{" "}
                  NFT LEFT of {+contractDetails?.totalSupplyNFT}
                </p>
              )}

              <div className={`form-counter ${userData?.account && "mb-0"}`}>
                <button
                  onClick={handleCounter}
                  type="button"
                  className="counter-btn"
                  data-pos="minus"
                >
                  -
                </button>
                <span> {count} </span>
                <button
                  onClick={handleCounter}
                  type="button"
                  className="counter-btn"
                  data-pos="plus"
                >
                  +
                </button>
              </div>
              {userData?.account && (
                <p className="mint__account">
                  Account: {userData?.account.slice(0, 5)}...
                  {userData?.account.slice(-4)}
                </p>
              )}
              {!loading && userData && (
                <button
                  onClick={handleCustomMint}
                  disabled={loading}
                  type="button"
                  className="btn--mint"
                >
                  <span>Mint Now</span>
                </button>
              )}
              {!loading && !userData && (
                <>
                  <button
                    onClick={connectBrowserWallet}
                    disabled={loading}
                    type="button"
                    className="btn--mint"
                  >
                    <span>connect wallet</span>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {loading && <Loader />}
      </div>
      <img src={RightAv} alt="" className="avatar avatar-right" />
      <Toaster position="top-center" reverseOrder={false} />
    </section>
  );
}

export default App;
