import React, { createContext, useEffect, useState } from "react";
import abi from "../output/abi.json";
import { ethers } from "ethers";
import { toast } from "react-hot-toast";

// const keccak256 = require("keccak256")
// const {MerkleTree} = require('merkletreejs');

const CONTRACT_ADDRESS = "0x861db896A521a70e31A76b2a6f6429Aa50eFA99d";
const CORRECT_NET_ID = 1;

export const DAppContext = createContext(null);

export const DAppProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [transactionHash, setTransactionHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [contractDetails, setContractDetails] = useState(null);

  const getProviderData = async () => {
    try {
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);

      return {
        web3Provider,
      };
    } catch (e) {
      toast.error(`Please install Metamsk`);
      console.log(e);
    }
  };

  const connectToContract = async (provider, signer) => {
    setLoading(true);
    try {
      const instance = new ethers.Contract(CONTRACT_ADDRESS, abi, provider);
      const contractWithSigner = instance.connect(provider);
      let details = {};

      const {
        isActive,
        mintPrice,
        MAX_SUPPLY,
        totalSupply = () => {},
      } = contractWithSigner;

      const isPublicSaleActive = await isActive();
      const totalSupplyNFT = await MAX_SUPPLY();
      const totalMinted = await totalSupply();
      const publicETHPrice = ethers.utils.formatEther(`${await mintPrice()}`);

      const alreadyMinted = Number(await totalSupply());

      details = {
        ...details,
        price: publicETHPrice,
        isPublicSaleActive,
        totalSupplyNFT,
        alreadyMinted,
        methods: contractWithSigner,
        totalMinted,
      };

      setContractDetails(details);
    } catch (error) {
      console.log(error, "Error");
    } finally {
      setLoading(false);
    }
  };

  const connectBrowserWallet = async () => {
    try {
      setLoading(true);
      const { web3Provider } = await getProviderData();

      await web3Provider.send("eth_requestAccounts", []);
      const signer = web3Provider.getSigner();
      const accounts = await signer.getAddress();
      const balance = await web3Provider.getBalance(accounts);
      const { chainId } = await web3Provider.getNetwork();

      if (parseInt(chainId) !== CORRECT_NET_ID)
        return alert("Please change to MainNet");

      setUserData({
        account: accounts,
        chainId: Number(chainId),
        accountBalance: Number(ethers.utils.formatEther(balance)),
      });

      return true;
    } catch (error) {
      toast.error(`Please install Metamsk`);
      console.log(error, "Error");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const mint = async (count = 1) => {
    try {
      const account = userData.account;
      if (!contractDetails) return toast.error(`No instance`);
      if (!account)
        return toast.error(`No account selected. Try reauthenticating`);
      if (!count) return toast.error(`No token count provided.`);

      const { isActive, mint, mintPrice } = contractDetails.methods;

      const isPublicSaleActive = await isActive();
      const pusbliSaleCost = await mintPrice();
      const price = pusbliSaleCost;

      let cost = 0;
      const options = { value: cost };

      if (!isPublicSaleActive) return toast.error(`Sales has not start yet`);

      if (isActive) {
        cost = window.BigInt(`${count * price}`);
        options.value = cost;

        const { hash } = await mint(count, options);
        setTransactionHash(hash);
        setContractDetails({
          ...contractDetails,
          alreadyMinted: contractDetails.alreadyMinted + count,
        });
      }
    } catch (error) {
      alert(error.message);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const resetTransactionHash = () => {
    setTransactionHash("");
  };

  useEffect(() => {
    (async () => {
      const { web3Provider } = await getProviderData();
      connectToContract(web3Provider);
    })();
  }, []);

  return (
    <DAppContext.Provider
      value={{
        connectBrowserWallet,
        mint,
        loading,
        transactionHash,
        resetTransactionHash,
        contractDetails,
        userData,
      }}
    >
      {children}
    </DAppContext.Provider>
  );
};
